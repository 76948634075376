import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import {
  Dropdown,
  MessageModal,
} from '@makeably/creativex-design-system';
import styles from './RemappingModal.module.css';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  valuesPerCustomDimension: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      id: PropTypes.number,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  campaign: PropTypes.shape({
    accountId: PropTypes.string,
    channelLabel: PropTypes.string,
    customDimensions: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
};

const defaultProps = {
  campaign: {},
};

function renderField(name, value) {
  return (
    <div className="u-flexColumn u-gap-8">
      <div className="t-subtitle">{ `${name}:` }</div>
      <div className="t-body-1">{ value || 'N/A' }</div>
    </div>
  );
}

function RemappingModal({
  campaign,
  isOpen,
  valuesPerCustomDimension,
  onClose,
  onUpdate,
}) {
  if (!isOpen) {
    return null;
  }

  const [remappedDimensions, setRemappedDimensions] = useState({});

  const buttonDisabled = Object.keys(remappedDimensions).length === 0;

  const handleValueChange = (name, originalValue, value, parseId) => {
    setRemappedDimensions((currentDimensions) => {
      // Removes from the remapped dimension if the new value is the same as the existing one
      if (originalValue === value) {
        const { [name]: _unused, ...rest } = currentDimensions;
        return rest;
      }

      return {
        ...currentDimensions,
        [name]: {
          value,
          parseId,
        },
      };
    });
  };

  const dropdowns = () => {
    const campaignDimensions = campaign.customDimensions.map((dimension) => dimension.split('::'));

    return campaignDimensions.map((dimension) => {
      const [name, value, parseId] = dimension;
      const remappedValue = remappedDimensions[name]?.value;
      const options = valuesPerCustomDimension[name].values.map((v) => ({
        label: v,
        value: v,
      }));

      return (
        <Dropdown
          key={name}
          label={name}
          menuProps={{ size: 'medium' }}
          options={options}
          selected={options.find((option) => option.value === (remappedValue || value))}
          onChange={(val) => handleValueChange(name, value, val.value, parseId)}
        />
      );
    });
  };

  return (
    <FocusLock disabled={!isOpen} returnFocus>
      <MessageModal
        actionButtonDisabled={buttonDisabled}
        actionButtonLabel="Update"
        isOpen={isOpen}
        title="Re-map Campaign"
        onActionButtonClick={() => onUpdate(remappedDimensions)}
        onClose={onClose}
      >
        <div className="u-flexColumn u-gap-16 u-marginBottom-8">
          <div className={styles.name}>
            <div className="t-subtitle">Campaign Name:</div>
            <div className="t-body-1">{ campaign.name }</div>
          </div>
          <div className="u-flexRow u-gap-24">
            { renderField('Ad Account ID', campaign.accountId) }
            { renderField('Channel', campaign.channelLabel) }
          </div>
          { dropdowns() }
        </div>
      </MessageModal>
    </FocusLock>
  );
}

RemappingModal.propTypes = propTypes;
RemappingModal.defaultProps = defaultProps;

export default RemappingModal;
